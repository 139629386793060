@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Regular";
  src: local("Poppins-Regular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";
  src: local("Poppins-Medium"),
    url("./fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SemiBold";
  src: local("Poppins-SemiBold"),
    url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Light";
  src: local("Poppins-Light"),
    url("./fonts/Poppins-Light.ttf") format("truetype");
}

body {
  font-family: "Medium";
  background: theme("colors.greenGradientBody.green-gradient");
}

.fontLight {
  font-family: "Light";
}

.fontRegular {
  font-family: "Regular";
}

.fontBold {
  font-family: "SemiBold";
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 30px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff5c;
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
